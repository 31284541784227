import { Suspense, useEffect, useMemo } from 'react'

import type { NativeStackNavigationOptions } from '@react-navigation/native-stack'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useTranslation } from 'react-i18next'

import CancellationNavigator from '@/navigation/CancellationNavigator/lazy'
import Modals from '@/navigation/Modals/lazy'
import NewApplicationNavigator from '@/navigation/NewApplicationNavigator/lazy'
import RecoveryNavigator from '@/navigation/RecoveryNavigator/lazy'
import ReinstatementNavigator from '@/navigation/ReinstatementNavigator/lazy'
import TabNavigator from '@/navigation/TabNavigator/lazy'
import type { RootStackParamList } from '@/navigation/types'
import { defaultHeaderOptions } from '@/navigation/utils'

import SignInScreen from '@/screens/Auth/lazy'
import ChatScreen from '@/screens/Chat/lazy'
import HandoffScreen from '@/screens/Handoff'
import NotFoundScreen from '@/screens/NotFoundScreen/lazy'
import ReferralScreen from '@/screens/ReferralScreen'
import SignScreen from '@/screens/SignDocument/lazy'
import SignOutScreen from '@/screens/SignOut/lazy'
import WebViewScreen from '@/screens/WebView'

import { unregister } from '@/utils/analytics/posthog'
import {
  PermissionStatus,
  useTrackingPermissions,
} from '@/utils/analytics/tracking-transparency'
import useIdentifyCustomer from '@/utils/analytics/useIdentifyCustomer'
import { useAppStateRefetch } from '@/utils/api/react-query'
import { useAuthUser } from '@/utils/zustand'

const RootStack = createNativeStackNavigator<RootStackParamList>()

const tabNavigatorOptions = { headerShown: false }

function Navigation() {
  const { t } = useTranslation()
  const [permission, requestPermission] = useTrackingPermissions()

  const user = useAuthUser()

  useAppStateRefetch()

  useIdentifyCustomer({ disabled: !permission?.granted })

  useEffect(() => {
    // Reset super property
    unregister('application')
  }, [])

  useEffect(() => {
    if (permission?.status === PermissionStatus.GRANTED) return
    void requestPermission()
  }, [permission?.status, requestPermission])

  const newApplicationOptions = useMemo<NativeStackNavigationOptions>(
    () => ({
      animation: 'fade_from_bottom',
      gestureEnabled: false,
      headerBackVisible: false,
      // for Android
      headerShown: false,
      headerTitleAlign: 'center',
      title: t('titles.newApplication'),
    }),
    [t],
  )

  const chatOptions = useMemo<NativeStackNavigationOptions>(
    () => ({
      headerRight: () => null,
      headerTitle: t('titles.chat'),
      title: t('chat'),
    }),
    [t],
  )

  return (
    <>
      <Suspense fallback={null}>
        <Modals />
      </Suspense>
      <RootStack.Navigator initialRouteName="TabNavigator">
        <RootStack.Group
          screenOptions={(props) =>
            defaultHeaderOptions(props, { showMenu: false })
          }
        >
          <RootStack.Screen
            component={SignInScreen}
            initialParams={{ u: user?.signInDetails?.loginId }}
            // Pass user data, so we can redirect to app even if the
            // route was manually set to sign-in
            name="SignInScreen"
            options={{ title: t('titles.signIn') }}
          />
          <RootStack.Screen
            options={{
              headerTitle: t('headers.logOut'),
              title: t('titles.signOut'),
            }}
            component={SignOutScreen}
            name="SignOutScreen"
          />
          <RootStack.Screen component={HandoffScreen} name="HandoffScreen" />
        </RootStack.Group>

        <RootStack.Screen
          component={SignScreen}
          name="SignDocumentScreen"
          options={{ headerShown: false }}
        />

        <RootStack.Group screenOptions={defaultHeaderOptions}>
          <RootStack.Screen
            component={TabNavigator}
            name="TabNavigator"
            options={tabNavigatorOptions}
          />

          <RootStack.Screen
            component={NewApplicationNavigator}
            name="NewApplicationNavigator"
            options={newApplicationOptions}
          />

          {user ? (
            <RootStack.Group>
              <RootStack.Screen
                component={CancellationNavigator}
                name="CancellationNavigator"
                options={tabNavigatorOptions}
              />
              <RootStack.Screen
                component={ReinstatementNavigator}
                name="ReinstatementNavigator"
                options={tabNavigatorOptions}
              />

              <RootStack.Screen
                component={RecoveryNavigator}
                name="RecoveryNavigator"
                options={tabNavigatorOptions}
              />

              <RootStack.Screen
                component={ReferralScreen}
                name="ReferralScreen"
                options={{ headerBackVisible: true }}
              />
            </RootStack.Group>
          ) : null}

          <RootStack.Screen
            component={ChatScreen}
            name="ChatScreen"
            options={chatOptions}
          />

          <RootStack.Group
            screenOptions={(props) =>
              defaultHeaderOptions(props, {
                presentation: 'modal',
                showChat: false,
              })
            }
          >
            <RootStack.Screen
              component={WebViewScreen}
              name="WebViewScreen"
              options={{ title: t('titles.terms') }}
            />
          </RootStack.Group>

          <RootStack.Screen
            component={NotFoundScreen}
            name="NotFoundScreen"
            options={{ title: t('titles.notFound'), ...defaultHeaderOptions() }}
          />
        </RootStack.Group>
      </RootStack.Navigator>
    </>
  )
}

export default Navigation
