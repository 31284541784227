import type { AuthUser } from 'aws-amplify/auth'
import { create } from 'zustand'
import type { StateStorage } from 'zustand/middleware'
import { combine, createJSONStorage, persist } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'

import { zustandAuthStorage } from '@/utils/storage'

const mmkvStorage: StateStorage = {
  getItem: (name) => zustandAuthStorage.getString(name) ?? null,
  removeItem: (name) => zustandAuthStorage.delete(name),
  setItem: (name, value) => zustandAuthStorage.set(name, value),
}

const INITIAL_AUTH_STATE = {
  currentApplicationId: null as string | null,
  currentSessionId: null as string | null,
  customerId: null as string | null,
  handoff: null as null | {
    userType: 'CUSTOMER'
    entityId: string
    idToken: string
  },
  user: null as AuthUser | null,
  showEditDriverVehicleModal: false,
}

export const useAuthStore = create(
  persist(
    combine(INITIAL_AUTH_STATE, (set, state) => ({
      setCurrentApplicationId: (currentApplicationId: string | null) =>
        set({ ...state, currentApplicationId }),
    })),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => mmkvStorage),
    },
  ),
)

export function getAuthState() {
  return useAuthStore.getState()
}

export function setAuthStoreState(
  state: Partial<ReturnType<typeof useAuthStore.getState>>,
) {
  return useAuthStore.setState((prev) => ({ ...prev, ...state }))
}

export function getHandoff() {
  return useAuthStore.getState().handoff
}

export function getCustomerId() {
  return useAuthStore.getState().customerId
}

export function getCurrentSessionId() {
  return useAuthStore.getState().currentSessionId
}

export function getCurrentApplicationId() {
  return useAuthStore.getState().currentApplicationId
}

/**
 * Used to get either the customerId or the applicationId
 * used also for feature flag hashing
 */
export function getUserId() {
  return (
    useAuthStore.getState().customerId ||
    useAuthStore.getState().currentApplicationId
  )
}

export function useCustomerId() {
  return useAuthStore(useShallow((state) => state.customerId))
}

export function useSetCurrentApplicationId() {
  return useAuthStore(
    useShallow(({ setCurrentApplicationId }) => setCurrentApplicationId),
  )
}

export function useAuthUser() {
  return useAuthStore(useShallow(({ user }) => user))
}

export function useAuthApplicationId() {
  return useAuthStore(
    useShallow(
      ({ setCurrentApplicationId, currentApplicationId }) =>
        [currentApplicationId, setCurrentApplicationId] as const,
    ),
  )
}
