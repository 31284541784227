import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'

export const useChatBadgeStore = create(
  combine({ count: 0 }, (set, state) => ({
    increment: () => set({ count: state().count + 1 }),
    reset: () => set({ count: 0 }),
  })),
)

export function useChatBadge() {
  return useChatBadgeStore(
    useShallow(({ reset, increment }) => [increment, reset] as const),
  )
}

export function useChatBadgeCount() {
  return useChatBadgeStore(useShallow(({ count }) => count))
}
