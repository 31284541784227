import type { Application } from '@/.d'
import { captureException } from '@sentry/react-native'
import type { UseMutationOptions } from '@tanstack/react-query'
import {
  type MutateOptions,
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import merge from 'lodash.merge'
/* eslint-disable max-lines */
import { useEffect } from 'react'
import { Platform } from 'react-native'

import { getBool } from '@/utils'
import * as analytics from '@/utils/analytics'
import { Events } from '@/utils/analytics/events'
import { axiosInstance, getIdemPotencyKeyHeader } from '@/utils/api'
import { customerKeys } from '@/utils/api/customer'
import { getIssuerQuestionsMetadata } from '@/utils/api/issuerQuestions'
import { productKeys } from '@/utils/api/products'
import type { APIResponse, Document, PaymentAccount } from '@/utils/api/types'
import { getResponseData } from '@/utils/api/utils'
import { getIsAuthenticated } from '@/utils/auth'
import type {
  InclusionStatus,
  RateCall,
  UpdateApplicationError,
  UpdateApplicationVariables,
} from '@/utils/constants'
import { ApplicationStatus, isDevelopment } from '@/utils/constants'
import { MissingApplicationId } from '@/utils/errors'
import { useDebouncedQuery } from '@/utils/hooks/useDebounceQuery'
import queryClient from '@/utils/queryClient'
import Sentry from '@/utils/sentry'
import { getCurrentApplicationId, setAuthStoreState } from '@/utils/zustand'

import * as AdServices from '../../../../modules/expo-adservices'
import {
  completeFinalQuotesRequirements,
  updateProofDocumentation,
} from './api'

type UpdateApplicationOptions = {
  forceUpdate?: boolean
  status: ApplicationStatus
  data: UpdateApplicationField[]
}

type GetCurrentApplicationErrorResponse = {
  errorCode: string
  errorMessage: string
  fieldErrors: {
    field: string
    code: string
    message: string
    rejectValue?: string | null
  }[]
}

export type AttributionData = {
  attribution: boolean
  orgId: number
  campaignId: number
  conversionType: string
  clickDate: string
  adGroupId: number
  countryOrRegion: string
  keywordId: number
  adId: number
}

function getAdAttributionData() {
  try {
    const token = AdServices.getAttributionToken()
    if (!token) return null

    const data = globalThis
      .fetch(`https://api-adservices.apple.com/api/v1/`, {
        method: 'POST',
        headers: { 'Content-Type': 'text/plain' },
        body: token,
      })
      .then((response) => response.json() as Promise<AttributionData | null>)

    return data
  } catch (error) {
    return null
  }
}

function getAttributionSource(adAttributionData?: AttributionData) {
  if (Platform.OS === 'ios') {
    if (adAttributionData) return 'AppleSearchAds'
    return 'AppStore'
  }

  if (Platform.OS === 'android') {
    return 'GooglePlay'
  }

  return ''
}

function getAttributionMedium(adAttributionData?: AttributionData) {
  if (Platform.OS === 'ios') {
    return adAttributionData?.adGroupId?.toString() ?? ''
  }

  return 'cpc'
}

function getApplicationUpdateUrl(currentStatus: ApplicationStatus) {
  const baseAuthenticatedUrl = `/autoFlow/applications/${getCurrentApplicationId()}`
  const basePublicUrl = `/public${baseAuthenticatedUrl}`

  let patchUrl = ''
  switch (currentStatus) {
    case ApplicationStatus.New:
    case ApplicationStatus.Lead:
      // SPECIAL_CASE: the application status is still in new, but we already have an
      // authenticated user
      patchUrl = basePublicUrl
      break
    case ApplicationStatus.DriverIncidentDiscovery:
      patchUrl = `${baseAuthenticatedUrl}/driverIncident`
      break
    // This is the case for partner flow
    case ApplicationStatus.AccountCreated:
      patchUrl = `${baseAuthenticatedUrl}/initialDriverVehicle`
      break
    case ApplicationStatus.QuotesRateCall1:
    case ApplicationStatus.PolicyGenerated:
    case ApplicationStatus.QuotesRateCall15:
    case ApplicationStatus.QuotesRateCall1Anon:
    case ApplicationStatus.PolicyGenerationPending:
      patchUrl = `${baseAuthenticatedUrl}/creditApplication`
      break
    case ApplicationStatus.QuotesFinal:
      patchUrl = `${baseAuthenticatedUrl}/finalQuotes/confirm`
      break
    case ApplicationStatus.IssuerQuestions:
      // This will grab the issuer questions with multiple
      // options and wrap it to be just a comma separated set of values
      patchUrl = `${baseAuthenticatedUrl}/issuerQuestions`
      break
    case ApplicationStatus.QuotesFinalAccepted:
    case ApplicationStatus.ProofDocumentation:
      patchUrl = `${baseAuthenticatedUrl}/proofDocumentation`
      break
    case ApplicationStatus.DriverVehicleDiscovery:
      patchUrl = `${baseAuthenticatedUrl}/driverVehicle`
      break
    case ApplicationStatus.Expired:
      throw new Error('Application is expired')
    default:
      throw new Error(`MISSING ENDPOINT FOR: ${currentStatus}`)
  }

  return patchUrl
}

type CreateApplicationParams = {
  lng?: string
  tracking_id?: string
  medium?: string
  source?: string
  partner?: string
  campaign?: string
  postalCode?: string
  term?: string
  content?: string
  preferredLanguage?: string
}

export const applicationKeys = {
  current: ['current-application'] as const,
  documentAnchors: (documentType: string) =>
    [...applicationKeys.current, 'document-anchors', documentType] as const,
  documents: () => [...applicationKeys.current, 'documents'] as const,
  documentsAttachments: (applicationId: string) =>
    [...applicationKeys.documents(), 'attachments', applicationId] as const,
  finalQuoteLoan: () =>
    [...applicationKeys.current, 'final-quote-loan'] as const,
  issuerQuestions: () =>
    [...applicationKeys.current, 'issuer-questions'] as const,
  issuerQuestionsMetadata: (options?: Record<string, any>) =>
    [...applicationKeys.issuerQuestions(), 'metadata', options] as const,
  quotes: (options: Record<string, any>) =>
    [...applicationKeys.current, 'quotes', options] as const,
}

export function useCompleteFinalQuotesRequirements(
  options?: UseMutationOptions<any, any>,
) {
  return useMutation({
    mutationFn: () =>
      completeFinalQuotesRequirements(getCurrentApplicationId() as string),
    mutationKey: ['complete-final-quotes-requirements'],
    ...options,
  })
}

export function getFormattedForIssuerQuestions(data: UpdateApplicationField[]) {
  return data.reduce((acc, item) => {
    if (item.path.match(/\.\d+/)) {
      const fieldName = item.path.split('.')
      fieldName.length = fieldName.length - 1
      const fieldName2 = fieldName.join('.')

      const existingValue = acc.find(({ path }) => path === fieldName2)?.value

      if (existingValue) {
        return acc.map(($0) =>
          $0.path === fieldName2
            ? { ...$0, value: `${$0.value},${item.value}` }
            : $0,
        )
      }

      return acc.concat({ path: fieldName2, value: item.value })
    }

    return acc.concat(item as any)
  }, [] as UpdateApplicationField[])
}

export type UpdateApplicationResponse = APIResponse<Application>

/**
 *
 * This API call will also set to the store the current session and
 * the current application id
 */
export function useCreateApplication(
  options?: MutateOptions<
    any,
    AxiosError<
      { errorCode?: string; fieldErrors?: { code?: string }[] } | undefined
    >,
    CreateApplicationParams
  >,
) {
  const queryClient = useQueryClient()

  // We will ignore lng from parameters to avoid setting this field right away
  // we will prefill this in formik only
  return useMutation({
    async mutationFn(params) {
      /* Ignore lng parameter, it should have been set in i18n and user will confirm
        selection after account creation. Unless the app was created manually in the appflow */
      const { lng: _lng, ...parameters } = params

      let adAttributionData: AttributionData | undefined
      try {
        adAttributionData = await getAdAttributionData()
        if (!adAttributionData || !Object.values(adAttributionData))
          throw new Error('No ad attribution data')
      } catch {
        adAttributionData = undefined
      }

      parameters.campaign = adAttributionData?.campaignId?.toString() ?? ''
      parameters.source = getAttributionSource(adAttributionData)
      parameters.medium = getAttributionMedium(adAttributionData)
      parameters.content = adAttributionData?.keywordId?.toString() ?? ''
      parameters.term = adAttributionData?.conversionType?.toString() ?? ''

      const filteredParameters = Object.fromEntries(
        Object.entries(parameters).filter(
          ([, value]) => typeof value === 'string',
        ),
      ) as { [key: string]: string }

      let searchParams: URLSearchParams
      if (Object.keys(filteredParameters).length > 0) {
        searchParams = new URLSearchParams(filteredParameters)
      } else {
        searchParams = new URLSearchParams()
      }

      // It would be nicer if the variables were posted in the body, but
      // the API doesn't support it.
      return axiosInstance
        .post<
          APIResponse<Application>
        >(`/public/autoFlow/applications?${searchParams}`, null, { headers: { ...getIdemPotencyKeyHeader() } })
        .then((response) => {
          const data = getResponseData(response)

          if (!data) return undefined

          try {
            // Remove try wrapper after metrics are out of beta
            Sentry.metrics.set('create--application', data.id)
          } catch {
            // ignore
          }

          setAuthStoreState({
            currentApplicationId: data.id,
            currentSessionId: data.customSessionId,
          })

          analytics.track({ id: Events.CreateApplication })
          /**
           * Legacy event, don't remove until Facebook is updated with
           * create application
           */
          analytics.track({ id: 'start_application' as any })

          queryClient.setQueryData<Application>(applicationKeys.current, data, {
            updatedAt: Date.now(),
          })

          return data
        })
    },
    mutationKey: ['create-application'],
    ...options,
  })
}

export type UpdateApplicationField = {
  path: string
  value?: string | number | null
  type?: InclusionStatus
}

export async function updateApplication({
  data,
  forceUpdate,
  status: currentStatus,
}: UpdateApplicationOptions) {
  const patchUrl = getApplicationUpdateUrl(currentStatus)

  if (currentStatus === ApplicationStatus.IssuerQuestions) {
    // This will grab the issuer questions with multiple
    // options and wrap it to be just a comma separated set of values
    data = getFormattedForIssuerQuestions(data)
  }

  // If we are not passing new data, just return existing data from cache
  // this currently works to enable changing steps

  if (!data.length) {
    return queryClient.getQueryData<Application>(applicationKeys.current)
  }

  const responseData = await axiosInstance
    .patch<UpdateApplicationResponse>(patchUrl, data, {
      headers: getIdemPotencyKeyHeader(),
    })
    .then(getResponseData)

  // Only being used for 'src/screens/NewApplication/Steps/Quotes/index.tsx:208'
  if (forceUpdate) {
    queryClient.setQueryData<Application>(
      applicationKeys.current,
      (prevQueryData: any) => {
        const newQueryData = {
          ...prevQueryData,
          data: {
            ...prevQueryData?.data,
            quote: {
              ...prevQueryData?.data.quote,
              rateCall1Id:
                prevQueryData?.data.quote?.rateCall1Id ??
                responseData?.data?.quote?.rateCall1Id,
              rateCall1Issuer:
                prevQueryData?.data.quote?.rateCall1Issuer ??
                responseData?.data?.quote?.rateCall1Issuer,
            },
          },
          status: responseData?.status ?? prevQueryData?.status,
        }

        return newQueryData
      },
    )
  }

  return responseData
}

export function useUpdateApplication(
  options?: MutateOptions<
    Awaited<ReturnType<typeof updateApplication>>,
    UpdateApplicationError,
    UpdateApplicationVariables
  >,
) {
  return useMutation({
    mutationFn({ values, status, forceUpdate }) {
      const applicationId = getCurrentApplicationId()

      if (!applicationId) {
        throw new MissingApplicationId()
      }

      // Send status to know which endpoint to call
      return updateApplication({ data: values, forceUpdate, status }).then(
        (data) => {
          if (!data) return undefined

          queryClient.setQueryData<Application>(
            applicationKeys.current,
            (prevData) =>
              // Merge prevents removing data from other endpoints like accounts and
              // issuer questions which are kept for other purposes.
              // Add empty object source to prevent mutating prevData
              merge({}, prevData, data),
            {
              // IMPORTANT: Forces onSuccess() call of useCurrentApplication hook
              updatedAt: Date.now(),
            },
          )

          return data
        },
      )
    },
    mutationKey: ['update-application'],
    ...options,
  })
}

export function useAutoInsuranceName(
  name: string,
  options?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAutoInsuranceName>>,
      AxiosError<unknown>
    >
  >,
) {
  return useDebouncedQuery({
    ...options,
    debounce: 500,
    enabled: Boolean(name),
    queryFn: () => getAutoInsuranceName(name),
    queryKey: ['auto-insurance-name', name],
  })
}

export function updateConversionEvent(
  conversionEvent: string,
  applicationId: string,
) {
  if (!applicationId) {
    throw new MissingApplicationId()
  }

  return axiosInstance
    .patch(
      `/autoFlow/applications/${applicationId}/conversionEvents/${conversionEvent}`,
      {},
      { headers: getIdemPotencyKeyHeader() },
    )
    .catch(captureException)
}

export function getApplication(
  applicationId?: string,
  isAuthenticated?: boolean,
) {
  if (!applicationId) {
    throw new MissingApplicationId()
  }

  const prefix = isAuthenticated ? '' : 'public/'

  return axiosInstance
    .get<
      APIResponse<Application>
    >(`/${prefix}autoFlow/applications/${applicationId}`)
    .then((response) => {
      const data = getResponseData(response)
      if (!data) throw new Error('No application found')

      return data
    })
}

export const APPLICATION_STATUS_WITH_PAYMENT_NEEDED = [
  ApplicationStatus.QuotesFinalAccepted,
  ApplicationStatus.PolicyGenerated,
  ApplicationStatus.PolicyGenerationPending,
  ApplicationStatus.PolicyGenerationAgentLocked,
  ApplicationStatus.PolicyBound,
]

export async function getApplicationWithExtra(staticApplicationId?: string) {
  const isAuthenticated = await getIsAuthenticated()

  const currentApplicationId = getCurrentApplicationId()

  const applicationId = staticApplicationId ?? currentApplicationId

  if (!applicationId) {
    // eslint-disable-next-line no-console
    if (isDevelopment) console.error('No current applicationId')
    throw new MissingApplicationId()
  }

  const application = await getApplication(applicationId, isAuthenticated)

  // Only fetch payment accounts for steps where we need to make that validation
  if (
    APPLICATION_STATUS_WITH_PAYMENT_NEEDED.includes(application.status) ||
    (getBool(application.data.isRenewal) &&
      application.status === ApplicationStatus.QuotesFinal)
  ) {
    const paymentAccounts = await getApplicationPaymentAccounts(
      applicationId,
      isAuthenticated,
    )

    application.data.paymentAccounts = paymentAccounts
  }

  if (application.status === ApplicationStatus.IssuerQuestions) {
    const issuerQuestions = await getIssuerQuestionsMetadata(applicationId)

    application.data.issuerQuestions = issuerQuestions
  }

  return application
}

export function useCurrentApplication(
  options?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApplicationWithExtra>>,
      AxiosError<GetCurrentApplicationErrorResponse>
    >
  >,
) {
  return useQuery({
    queryFn: () => getApplicationWithExtra(),
    queryKey: applicationKeys.current,
    staleTime: 0,
    ...options,
  })
}

export type SelectQuoteArguments = {
  data: {
    path:
      | 'quote.rateCall15Id'
      | 'quote.rateCall15Issuer'
      | 'quote.rateCall15IssuerContractType'
    value: string
  }[]
  reconfirm?: boolean
  nextState?: string
}

export function selectQuote({
  data,
  nextState,
  reconfirm,
}: SelectQuoteArguments) {
  const appId = getCurrentApplicationId()

  if (!appId) throw new MissingApplicationId()

  const path = reconfirm ? 'reconfirm' : 'confirm'

  return axiosInstance
    .patch<APIResponse<Application>>(
      `/autoFlow/applications/${appId}/secondQuotes/${path}`,
      data,
      { headers: { ...getIdemPotencyKeyHeader() } },
    )
    .then(getResponseData)
    .then((data) => {
      if (!data) return undefined

      queryClient.setQueryData<Application>(
        applicationKeys.current,
        (prevData) => merge({}, prevData, { status: nextState ?? data.status }),
        { updatedAt: Date.now() },
      )

      return data
    })
}

export function useSelectQuote(
  _rateCall: RateCall,
  options?: MutateOptions<
    Awaited<ReturnType<typeof selectQuote>>,
    AxiosError<unknown>,
    Parameters<typeof selectQuote>[0]
  >,
) {
  return useMutation({
    mutationFn: ({ data, nextState }) => selectQuote({ data, nextState }),
    mutationKey: ['select-quote'] as const,
    ...options,
  })
}

export function getApplicationDocuments({
  applicationId,
  ...params
}: {
  applicationId: string
  documentType?: string
}) {
  if (!applicationId) throw new MissingApplicationId()

  return axiosInstance
    .get<
      APIResponse<Document[]>
    >(`/autoFlow/applications/${applicationId}/documents`, { params })
    .then(getResponseData)
}

export function useApplicationDocuments(
  options?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getApplicationDocuments>>,
      AxiosError<unknown>
    >
  >,
) {
  return useQuery({
    queryFn: () =>
      getApplicationDocuments({
        applicationId: getCurrentApplicationId() as string,
      }),
    queryKey: applicationKeys.documents(),
    ...options,
  })
}
export function useStartRecovery(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof startRecovery>>,
    AxiosError<unknown>,
    Parameters<typeof startRecovery>[0]
  >,
) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (productId: string) => {
      const response = await startRecovery(productId).catch(captureException)
      // Invalidate the current application to force a new fetch
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: applicationKeys.current }),
        queryClient.invalidateQueries({ queryKey: productKeys.all }),
        queryClient.invalidateQueries({ queryKey: customerKeys.all }),
      ])
      setAuthStoreState({ currentApplicationId: null })
      return response
    },
    ...options,
  })
}

export function getApplicationPaymentAccounts(
  applicationId: string,
  enabled = true,
) {
  if (!enabled) return Promise.resolve([])
  if (!applicationId) throw new MissingApplicationId()

  return axiosInstance
    .get<
      APIResponse<PaymentAccount[]>
    >(`/autoFlow/applications/${applicationId}/paymentAccounts`)
    .then(getResponseData)
}

export function resetApplicationStatus(
  applicationId: string,
  params: {
    newState?: ApplicationStatus
    resetTo?: 'drivers' | 'vehicles'
  },
) {
  if (!applicationId) throw new MissingApplicationId()

  return axiosInstance.patch(
    `/autoFlow/applications/${applicationId}/resetStatus`,
    null,
    { headers: getIdemPotencyKeyHeader(), params },
  )
}

export function useResetApplicationStatus(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof resetApplicationStatus>>,
    AxiosError<unknown>,
    Parameters<typeof resetApplicationStatus>[1]
  >,
) {
  return useMutation({
    mutationFn: (params) =>
      resetApplicationStatus(getCurrentApplicationId() as string, params),
    mutationKey: ['reset-application-status'],
    ...options,
  })
}

export function saveSignature({
  applicationId,
  data,
}: {
  applicationId: string
  data: any
}) {
  analytics.track({ id: Events.SubmitDocumentsSignature })

  return axiosInstance.patch(
    `/autoFlow/applications/${applicationId}/documents/signatures`,
    data,
    { headers: getIdemPotencyKeyHeader() },
  )
}

export function getAutoInsuranceName(name: string) {
  return axiosInstance
    .get<
      APIResponse<Array<{ code: string; name: string }>>
    >('public/insurances/auto/issuers', { params: { name } })
    .then(getResponseData)
}

export function startRecovery(productId: string) {
  return axiosInstance
    .post(`/autoFlow/products/${productId}/renewals`, undefined, {
      headers: getIdemPotencyKeyHeader(),
    })
    .then(getResponseData)
}

export function useUpdateProofDocumentation(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof updateProofDocumentation>>,
    AxiosError<unknown>,
    Parameters<typeof updateProofDocumentation>[1]
  >,
) {
  const response = useMutation({
    mutationFn: (data: Parameters<typeof updateProofDocumentation>[1]) =>
      updateProofDocumentation(getCurrentApplicationId() as string, data),
    mutationKey: ['update-proof-documentation'],
    ...options,
  })

  useEffect(() => {
    if (!response.data) return

    queryClient.setQueryData<Application>(
      applicationKeys.current,
      response.data,
    )
  }, [response.data])

  return response
}
