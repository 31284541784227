import { AnalyticsBrowser } from '@segment/analytics-next'
import { captureException } from '@sentry/react-native'

import {
  alias as phAlias,
  identify as phIdentify,
  posthogClient,
  screen,
} from '@/utils/analytics/posthog'
import { isDevelopment } from '@/utils/constants'
import Sentry from '@/utils/sentry'
import {
  getAuthState,
  getCurrentApplicationId,
  getCustomerId,
  getUserId,
} from '@/utils/zustand'

import * as firebase from './firebase'
import type { AnalyticsEvent, IdentifyArgs } from './utils'
import { appInformation, formatUserTraits } from './utils'

const segmentId = process.env.EXPO_PUBLIC_SEGMENT_ID as string

window?.addEventListener('appinstalled', () => {
  track({ id: 'ui__app_installed' })
})

let segmentClient: undefined | AnalyticsBrowser

declare global {
  interface Window {
    gtag: (action: string, scope: string, data: any) => void
  }
}

export function initialize() {
  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.log('Not initializing analytics in development')
    return
  }

  try {
    if (!segmentId) throw new Error('Missing segment id')

    segmentClient = AnalyticsBrowser.load({ writeKey: segmentId })
  } catch (error) {
    Sentry.captureException(error)
  }

  try {
    posthogClient?.register(appInformation)
  } catch (error) {
    Sentry.captureException(error)
  }
}

/**
 * The Alias method is an advanced method used to merge 2 unassociated user identities,
 * effectively connecting 2 sets of user data in one profile.
 */
export async function alias(currentUserId: string, previousUserId: string) {
  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.log(`Not aliasing user "${previousUserId}" in development`, {
      currentUserId,
      previousUserId,
    })
    return
  }

  if (!currentUserId || !previousUserId) {
    return
  }

  await Promise.allSettled([
    phAlias(currentUserId, previousUserId),
    segmentClient?.alias(currentUserId, previousUserId),
  ])
}

export async function identify(
  { userId = getUserId(), ...userInformation }: IdentifyArgs = {
    userId: getUserId(),
  },
  options: { omitPosthog?: boolean } = { omitPosthog: false },
) {
  // Sentry user context
  try {
    const store = getAuthState()
    if (store.customerId) {
      Sentry.setTag('customerId', store.customerId)
    }
    if (store.currentApplicationId) {
      Sentry.setTag('applicationId', store.currentApplicationId)
    }
    if (store.user?.userId) {
      Sentry.setUser({ username: store?.user?.signInDetails?.loginId })
    }
  } catch (error) {
    Sentry.captureException(error)
  }

  if (isDevelopment && userId) {
    // eslint-disable-next-line no-console
    console.log(`Not identifying user "${userId}" in development`, {
      metadata: userInformation,
      userId,
    })
    return
  }

  const userTraits = formatUserTraits(userInformation)

  // Product analytics
  await Promise.allSettled([
    segmentClient
      ?.identify(getCustomerId(), userTraits, {
        anonymousId: getCurrentApplicationId() ?? undefined,
      })
      .catch(captureException),
    segmentClient?.ready(() =>
      // NOTE: Necessary for Google Ads attribution
      globalThis.window.gtag('set', 'user_data', userTraits),
    ),
    options.omitPosthog || !userId
      ? Promise.resolve()
      : phIdentify(userId, userTraits),
    firebase.identify({ ...userTraits, metadata: null, address: null }),
  ])
}

export async function track(event: AnalyticsEvent) {
  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.log(`Not tracking event "${event.id}" in development`, { event })
    return
  }

  await Promise.allSettled([
    segmentClient?.track(event.id, {
      ...(event?.metadata ?? {}),
      ...appInformation,
    }),
    posthogClient?.capture(event.id, event.metadata),
    firebase.logEvent(event.id, event.metadata),
  ])
}

export async function screenView(pageName: string, metadata = {}) {
  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.log(
      `Not tracking screen view "${pageName}" in development`,
      metadata,
    )
    return
  }

  await Promise.allSettled([
    segmentClient?.page(pageName, { ...metadata, ...appInformation }),
    screen(pageName, metadata),
    firebase.logScreenView(pageName),
  ])
}
