import { Platform } from 'react-native'

const environment = process.env.EXPO_PUBLIC_ENVIRONMENT as string
const variant = process.env.EXPO_PUBLIC_VARIANT || 'production'

import { appVersion, buildVersion } from '@/utils/constants'

import type { Events } from './events'

export type IdentifyArgs = { [flagName: `has${string}`]: string | boolean } & {
  age?: number
  email?: string
  /** customerId or applicationId */
  userId?: string | null
  gender?: string
  phone?: string
  firstName?: string
  lastName?: string
  language?: string
  vehicleYear?: string
  vehicleMake?: string
  vehicleTrim?: string
  vehicleModel?: string
  maritalStatus?: string
  vehicleOwnership?: string
  o_application_mode?: string
  o_application_source?: string
  o_application_partner?: string
  address?: Partial<
    Record<'street' | 'city' | 'state' | 'postalCode' | 'country', string>
  >
}

export type AnalyticsEvent = {
  id: Events | `${'ui' | 'error' | 'submit'}__${string}`
  metadata?: Record<string, any>
}

export function formatUserTraits(information: IdentifyArgs) {
  let gender = information.gender?.toLowerCase()
  gender = gender === 'unspecified' ? 'unisex' : gender

  let userTraits = {
    ...information,
    gender,
    // special casing for gtag
    first_name: information.firstName,
    last_name: information.lastName,
    phone_number: information.phone,
  }

  // Remove empty values
  userTraits = Object.fromEntries(
    Object.entries(userTraits).filter(([, value]) => Boolean(value)),
  ) as typeof userTraits

  if (Object.keys(userTraits).length === 0) {
    userTraits = undefined
  }

  return userTraits
}

export const appInformation = {
  build: buildVersion,
  environment,
  platform_os: Platform.OS,
  platform_version: Platform.Version,
  variant,
  version: appVersion,
}
