import * as Haptics from 'expo-haptics'
import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'

import Sentry from '@/utils/sentry'

const DEFAULT_BANNER_DURATION = 5000

type Banner = {
  key: number
  body: string
  duration: number
  showRefresh?: boolean
}

export const useBannersStore = create(
  combine({ current: [] as Banner[] }, (set, state) => ({
    remove: () => {
      const [, ...newCurrent] = state().current
      return set({ current: newCurrent })
    },
    show: (body: string, options?: Omit<Banner, 'text'>) => {
      Sentry.addBreadcrumb({
        category: 'ui-event',
        data: { body, options },
        message: 'Banner shown',
      })

      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light).catch(
        () => undefined,
      )

      const newBanner = {
        body,
        duration: options?.duration ?? DEFAULT_BANNER_DURATION,
        key: options?.key ?? Date.now(),
      }
      return set({ current: state().current.concat(newBanner) })
    },
  })),
)

export function useShowBanner() {
  return useBannersStore(useShallow(({ show }) => show))
}

export function useBanners() {
  return useBannersStore(
    useShallow(({ current, remove }) => [current, remove] as const),
  )
}
