import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'

import type { ModalId } from '@/navigation/Modals'
import type { ModalOptions } from '@/navigation/Modals/utils'

import Sentry from '@/utils/sentry'

type ShowModalOptions = Partial<
  Omit<ModalOptions, 'count' | 'level' | 'setHeading'>
>
export const useModalsStore = create(
  combine({ modals: [] as ({ id: ModalId } & ShowModalOptions)[] }, (set) => ({
    dismiss: (modalId?: string) =>
      set((state) => ({
        modals: modalId
          ? state.modals.filter((modal) => modal.id !== modalId)
          : [...state.modals].slice(0, -1),
      })),
    show: (id: ModalId, options?: ShowModalOptions) => {
      Sentry.addBreadcrumb({
        category: 'ui-event',
        data: { id, options },
        message: 'Modal shown',
      })

      return set((state) => ({
        modals: [...state.modals, { id, ...options }],
      }))
    },
  })),
)

export function useShowModal() {
  return useModalsStore(useShallow((state) => state.show))
}

export function useModals() {
  return useModalsStore(useShallow((state) => state.modals))
}
