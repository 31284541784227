import * as Haptics from 'expo-haptics'
import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'

import { AlertButton } from '@/components/Alert'

import Sentry from '@/utils/sentry'

export type Alert = {
  title: string
  body?: string
  onClose: () => void
  /** @deprecated */
  variant?: 'morado'
  buttons?: AlertButton[]
}

export const useAlertStore = create(
  combine({ current: null as Alert | null }, (set) => ({
    remove: () => set({ current: null }),
    show: (
      title: string,
      body?: string,
      options?: Omit<Alert, 'title' | 'body' | 'onClose'>,
    ) => {
      Sentry.addBreadcrumb({
        category: 'ui-event',
        data: { body, options, title },
        message: 'Alert shown',
      })

      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium).catch(
        () => undefined,
      )

      const onClose = () => set({ current: null })
      return set({ current: { body, title, ...options, onClose } })
    },
  })),
)

export function useShowAlert() {
  return useAlertStore(useShallow(({ show }) => show))
}

export function useAlert() {
  return useAlertStore(useShallow((state) => state.current))
}
