import { create } from 'zustand'
import { combine } from 'zustand/middleware'

export const usePendingUploadStore = create(
  combine({ value: [] as string[] }, (set) => ({
    add: (uploadId: string) =>
      set((prev) => ({ value: prev.value.concat(uploadId) })),
    remove: (uploadId: string) =>
      set((prev) => ({ value: prev.value.filter((id) => id !== uploadId) })),
  })),
)
