import { signOut as authSignOut } from 'aws-amplify/auth'

import { navigationRef } from '@/navigation/utils'

import queryClient from '@/utils/queryClient'
import Sentry from '@/utils/sentry'
import { utilStorage, zustandAuthStorage } from '@/utils/storage'
import { getAuthState, setAuthStoreState, useAuthStore } from '@/utils/zustand'

export default async function signOut({
  amplifySignOut,
  redirect,
}: { redirect?: boolean; amplifySignOut?: boolean } = {}) {
  let username: string | undefined

  await queryClient.cancelQueries()

  const authStore = getAuthState()
  if (authStore.user?.signInDetails?.loginId) {
    username = authStore.user.signInDetails.loginId
  }

  utilStorage.clearAll()
  zustandAuthStorage.clearAll()

  setAuthStoreState(useAuthStore.getInitialState())

  Sentry.setUser(null)
  Sentry.setTags({ applicationId: null, customerId: null })

  try {
    if (
      typeof navigator !== 'undefined' &&
      navigator.serviceWorker.controller
    ) {
      const registration = await navigator.serviceWorker.ready
      registration.active?.postMessage('RESET_API_CACHE')
    }
  } catch {
    // do nothing
  }

  await queryClient.clear()
  await queryClient.resetQueries()

  if (amplifySignOut) {
    await authSignOut()
  }

  if (redirect) {
    const params: { u?: string } = {}

    if (username) params.u = username

    navigationRef.reset({
      index: 0,
      routes: [{ name: 'SignInScreen', params }],
    })
  }
}
