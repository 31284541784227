/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import type { LinkingOptions } from '@react-navigation/native'
import * as Linking from 'expo-linking'

import type { RootStackParamList } from './types'

const prefixes = [
  Linking.createURL('/'),
  'https://app.ocho.co',
  `http://localhost:19006`,
]

const LinkingConfiguration: LinkingOptions<RootStackParamList> = {
  config: {
    screens: {
      ChatScreen: { path: '/chat' },
      HandoffScreen: { path: '/handoff' },
      NewApplicationNavigator: {
        path: '/new-application',
        screens: {
          IncidentScreen: {
            path: '/incident',
          },
          IssuerQuestionsScreen: {
            path: '/issuer-questions',
          },
          NewApplicationScreen: {
            path: '',
          },
          PolicyCoverageScreen: {
            path: '/policy-coverage',
          },
        },
      },
      NotFoundScreen: '*',
      RecoveryNavigator: {
        path: '/recovery',
        screens: {
          RecoveryScreen: { path: '' },
        },
      },
      ReferralScreen: { path: '/referral' },
      CancellationNavigator: {
        path: '/cancel-policy',
        screens: {
          CancellationScreen: { path: '' },
        },
      },
      ReinstatementNavigator: {
        path: '/reinstate',
        screens: {
          ReinstatementScreen: { path: '' },
        },
      },
      SignDocumentScreen: { path: '/sign-document' },
      SignInScreen: { path: '/sign-in' },
      SignOutScreen: { path: '/sign-out' },
      TabNavigator: {
        initialRouteName: 'HomeNavigator',
        path: '',
        screens: {
          HomeNavigator: {
            path: '',
            screens: {
              DocumentSignScreen: { path: 'document-sign/:id' },
              HomeScreen: {
                /** App's root path */
                path: '',
              },
              TasksScreen: { path: 'tasks' },
            },
          },
          PaymentsNavigator: {
            path: 'payments',
            screens: {
              PaymentHistoryScreen: { path: 'history' },
              PaymentScheduleScreen: { path: 'schedule' },
              PaymentScreen: { path: 'new' },
              PaymentsScreen: { path: '' },
            },
          },
          ProductsNavigator: {
            path: 'products',
            screens: {
              ProductsScreen: { path: '' },
            },
          },
          SettingsNavigator: {
            path: 'settings',
            screens: {
              AcknowledgementsScreen: { path: 'acknowledgements' },
              ChangePasswordScreen: { path: 'change-password' },
              DevicesScreen: { path: 'devices' },
              ProfileScreen: { path: 'profile' },
              SettingsScreen: { path: '' },
            },
          },
        },
      },
      WebViewScreen: { path: '/page' },
    },
  },
  prefixes,
}

export default LinkingConfiguration
